<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pa-2 pa-md-0>
      <v-flex xs12 align-self-center style="margin-all: 0px;">
        <v-layout wrap justify-center>
          <v-flex
            xs12
            lg10
            :style="
              $vuetify.breakpoint.name == 'xs'
                ? 'margin-top:0%'
                : 'padding-top:0%'
            "
            align-self-center
          >
            <v-layout wrap>
              <v-flex xs12 align-self-center text-center pt-8>
                <span style="font-size:26px;color:#000" class="poppinsbold">
                  Saving The Gentle Giants of the Marine World
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center py-0>
              <v-flex xs12 align-self-center pt-10>
                <v-card tile flat outlined color="#ffffff">
                  <v-layout wrap justify-center>
                    <v-flex xs12 md6 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center pa-4>
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'xs'
                                  ? 'font-size:16px;color:#000'
                                  : 'font-size:20px;color:#000'
                              "
                            >
                              <span
                                :style="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'font-size:14px'
                                    : $vuetify.breakpoint.name == 'xs'
                                    ? 'font-size:17px'
                                    : 'font-size:19px'
                                "
                              >
                                <span class="poppinsbold">
                                  Whale shark is the largest fish on earth
                                </span>
                                it can grow up to 60 ft. long and weigh as much
                                as 21 metric tons – which is larger than a
                                school bus! You’d be surprised to know that
                                despite its humungous size, it has never hurt a
                                human or even bigger marine animals. Whale
                                shark’s teeth are so tiny that they can only
                                filter and eat small fish, shrimp and plankton.
                                <br />
                                <br />
                                These gentle giants of the oceans have existed
                                for millions of years and are a keystone species
                                in our marine ecosystem. But not many people
                                known about them. Unlike the majestic land
                                animals such as the elephants and tigers, whale
                                sharks remain
                                <span class="poppinsbold"
                                  >the unsung contributors</span
                                >
                                to a healthy marine world we all depend on.
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 md6 align-self-center>
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele1.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-0 pb-0>
              <v-flex xs12 align-self-center pt-0>
                <v-card tile flat outlined color="#ffffff">
                  <v-layout wrap justify-center>
                    <v-flex
                      xs12
                      md6
                      align-self-center
                      class="hidden-sm-and-down"
                    >
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele2.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs12 md6 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center pa-4>
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'xs'
                                  ? 'font-size:16px;color:#000'
                                  : 'font-size:20px;color:#000'
                              "
                            >
                              <span
                                :style="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'font-size:14px'
                                    : $vuetify.breakpoint.name == 'xs'
                                    ? 'font-size:17px'
                                    : 'font-size:19px'
                                "
                              >
                                <span
                                  class="poppinsbold"
                                  :style="
                                    $vuetify.breakpoint.name == 'xs'
                                      ? 'font-size:19px'
                                      : 'font-size:21px'
                                  "
                                >
                                  Whale Sharks Are Dying…They Need Our Support
                                </span>
                                <br />
                                <br />

                                Accidental entanglement in fishing nets,
                                collision with fishing boats and extensive
                                coastal pollution are major threats to whale
                                sharks.
                                <br />
                                <br />

                                The species is currently ‘Endangered’ as per
                                IUCN red listing. For its protection, WTI has
                                contributed significantly in establishing whale
                                shark under Schedule 1 of the Wildlife
                                (Protection) Act, 1972. But it needs more than
                                just legal protection. It needs us, the
                                community and the will to save a dying species.
                                <br />
                                <br />

                                On the occasion of International Whale Shark Day
                                (30 August), we are launching a year-long ‘Save
                                the Whale Shark’ campaign off the coasts of
                                Karnataka, Kerala and Lakshadweep through which
                                we sensitise the fishing community and the youth
                                (who are the key stakeholders) towards
                                protecting the world’s largest fish.

                                <br />
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 align-self-center class="hidden-md-and-up">
                      <v-img
                        contain
                        :src="require('./../../assets/images/Ele2.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <!-- <v-layout wrap justify-center pt-0 pb-0>
              <v-flex xs12 align-self-center pt-10>
                <v-card tile flat outlined color="#92D050">
                  <v-layout wrap justify-center pa-2>
                    <v-flex xs12 md10 lg6 align-self-center>
                      <a
                        title="Click on the image to zoom"
                        :href="require('./../../assets/images/Ele3.jpg')"
                      >
                        <v-img
                          contain
                          :src="require('./../../assets/images/Ele3.jpg')"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#527E33"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </a>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout> -->

            <v-layout wrap justify-center pt-2 pb-6>
              <v-flex xs12 align-self-center pt-10>
                <v-card tile flat outlined color="#527E33">
                  <v-layout wrap justify-center>
                    <v-flex xs12 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex
                            xs12
                            align-self-center
                            text-xs-center
                            text-sm-center
                            pa-6
                          >
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'xs'
                                  ? 'font-size:16px;color:#ffffff'
                                  : 'font-size:20px;color:#ffffff'
                              "
                            >
                              <v-layout wrap>
                                <v-flex xs12 align-self-center text-center pa-2>
                                  <span
                                    style="font-size:24px"
                                    class="poppinsbold"
                                  >
                                    WHAT YOUR DONATIONS WILL FUND?
                                  </span>
                                </v-flex>
                              </v-layout>
                              Community engagement and sensitisation of primary
                              stakeholders – fishing community and youth – to
                              ensure
                              <span class="poppinsbold" style="font-size:26px;"
                                >ZERO killing</span
                              >
                              by release of individual whale sharks accidentally
                              entangled into the fishing nets.

                              <br />
                              <br />
                              Cleaning beaches off plastic, removal of ghost
                              nets (fishing nets that have been abandoned, lost
                              or otherwise discarded into the ocean) and marine
                              debris removal to prevent these from ending up in
                              a whale shark or any other creature’s digestive
                              system.

                              <br />
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pt-2 pb-6 pa-md-4 pa-lg-0>
              <v-flex xs12 align-self-center pa-1 pa-sm-0 py-sm-1>
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex
                      xs12
                      align-self-center
                      text-center
                      py-2
                      class="hidden-sm-and-up"
                    >
                      <span
                        :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:19px'
                            : 'font-size:17px'
                        "
                        class="poppinsbold "
                      >
                        Whale Shark Campaign launched in Karnataka – Updates
                      </span>
                    </v-flex>

                    <v-flex xs12 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center pa-4>
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'md'
                                  ? 'font-size:14px;color:#000;'
                                  : $vuetify.breakpoint.name == 'lg'
                                  ? 'font-size:19px !important;color:#000;'
                                  : 'font-size:17px !important;color:#000;'
                              "
                            >
                              <span
                                :style="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'font-size:20px'
                                    : 'font-size:21px'
                                "
                                class="poppinsbold hidden-xs-only"
                              >
                                Whale Shark Campaign launched in Karnataka –
                                Updates
                                <br />
                                <br />
                              </span>

                              <!-- <br />
                              <br /> -->
                              <span class="poppinssemibold"> </span>

                              ‘Save the Whale Shark Campaign’ was launched in
                              Mangalore on 30th August, celebrating
                              International Whale Shark Day. The event was
                              hosted at Milagres Hall Complex, Mangalore, with
                              an audience of 250 people including the fisheries
                              department, fishermen community members, forest
                              department representatives and bureaucrats and
                              students. The chief guest of the event was
                              <span class="poppinssemibold"
                                >Mr. Angara Sullia, the Hon. State Minister for
                                Fisheries, Ports and Inland Development,
                                Karnataka.</span
                              >
                              Other dignitaries on the dais included Mr. Vivek
                              Menon (Founder, Trustee and Executive Director,
                              WTI),
                              <span class="poppinssemibold"
                                >Mr Clifford Lobo </span
                              >(ACF, Mangalore Division),<span
                                class="poppinssemibold"
                              >
                                Mr Subbaiyya Naik</span
                              >
                              (DCF, Mangalore Forest Division),
                              <span class="poppinssemibold"
                                >Dr H N Anjaneyappa </span
                              >(Dean, College of Fisheries, Mangalore) and<span
                                class="poppinssemibold"
                              >
                                Mr. Ananda Banerjee</span
                              >

                              (Director and Chief of Campaigns & Communications,
                              WTI).
                              <br />
                              <br />

                              Following the launch, the campaign will continue
                              to garner support from the local community,
                              fisheries and the forest departments and youth to
                              promote release of accidentally captured Whale
                              Sharks and reduce pollution along the coast of
                              Karnataka, Kerala and Lakshadweep.

                              <br />
                              <br />

                              <span class="poppinssemibold">
                                Next Stop for the campaign?</span
                              >
                              - Kerala, Thiruvananthapuram on 7th of October.
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 pa-2 align-self-center>
                      <!-- <v-carousel
                        cycle
                        height="100%"
                        hide-delimiter-background
                        show-arrows-on-hover
                      >
                        <v-carousel-item v-for="(wsslide, wi) in wsslides" :key="wi">
                          <v-img contain :src="wsslide">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#527E33"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-carousel-item>
                      </v-carousel> -->

                      <OwlCarousel
                        :autoplay="true"
                        :loop="true"
                        :nav="true"
                        :dots="true"
                        :responsive="owlResponsive"
                      >
                        <template v-for="(wsslide, wi) in wsslides">
                          <v-layout wrap :key="wi">
                            <v-flex pa-2>
                              <v-card flat color="rgba(0,0,0,0)" elevation="0">
                                <v-img contain height="100%" :src="wsslide">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="#FF6907"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </template>
                      </OwlCarousel>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pt-2 pb-6 pa-md-4 pa-lg-0>
              <v-flex xs12 align-self-center pa-1 pa-sm-0 py-sm-1>
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex
                      xs12
                      align-self-center
                      text-center
                      py-2
                      class="hidden-sm-and-up"
                    >
                      <span
                        :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:19px'
                            : 'font-size:17px'
                        "
                        class="poppinsbold "
                      >
                        We have done this in the past…Gujarat and the whale
                        shark saga
                      </span>
                    </v-flex>

                    <v-flex xs12 md7 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center pa-4>
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'md'
                                  ? 'font-size:14px;color:#000;'
                                  : $vuetify.breakpoint.name == 'lg'
                                  ? 'font-size:19px !important;color:#000;'
                                  : 'font-size:17px !important;color:#000;'
                              "
                            >
                              <span
                                :style="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'font-size:20px'
                                    : 'font-size:21px'
                                "
                                class="poppinsbold hidden-xs-only"
                              >
                                We have done this in the past…Gujarat and the
                                whale shark saga
                                <br />
                                <br />
                              </span>

                              <!-- <br />
                              <br /> -->
                              Through a similar project off the Gujarat coast
                              (which hosts the largest annual aggregation of
                              whale sharks followed by Kerala and Lakshadweep),
                              <span class="poppinssemibold">
                                857 whale sharks accidentally entangled in
                                fishing nets were rescued and released into the
                                Arabian sea since 2004.
                              </span>
                              <br />
                              <br />

                              Gujarat was once a hub of whale shark fisheries
                              and hunting. But, since the project began, we are
                              proud to say there have been
                              <span class="poppinssembold"
                                >ZERO killings of whale sharks in Gujarat.</span
                              >
                              The project turned the fishermen and the local
                              community from
                              <span class="poppinssemibold"
                                >hunters to protectors</span
                              >
                              of the species and repositioned whale shark as the
                              <span class="poppinssemibold"
                                >‘Pride of Gujarat’.</span
                              >
                              <br />
                              <br />

                              Whale Shark is distributed all along the Indian
                              coast. To RECOVER this ‘Endangered’ species, we
                              are expanding to Karnataka, Kerala and
                              Lakshadweep, which host the second highest
                              aggregation of the species off the Indian west
                              coast.
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md5
                      align-self-center
                      class="hidden-sm-and-down"
                    >
                      <v-carousel
                        cycle
                        height="100%"
                        hide-delimiter-background
                        show-arrows-on-hover
                      >
                        <v-carousel-item v-for="(slide, i) in slides" :key="i">
                          <v-img contain :src="slide">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#527E33"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-carousel-item>
                      </v-carousel>
                    </v-flex>

                    <v-flex
                      xs12
                      sm12
                      md3
                      align-self-center
                      class="hidden-md-and-up"
                    >
                      <v-carousel
                        cycle
                        height="auto"
                        hide-delimiter-background
                        show-arrows-on-hover
                      >
                        <v-carousel-item v-for="(slide, i) in slides" :key="i">
                          <v-img contain :src="slide">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#527E33"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-carousel-item>
                      </v-carousel>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pt-2 pb-6 pa-md-4 pa-lg-0 py-lg-4>
              <v-flex xs12 align-self-center>
                <v-card
                  outlined
                  flat
                  tile
                  color="#ffffff"
                  style="border:2px solid #527E33"
                >
                  <v-layout wrap pa-2>
                    <v-flex xs12 align-self-center text-center pa-2>
                      <span class="poppinssemibold" style="font-size:20px">
                        Join us as we launch ‘Save the Whale Shark’ campaign in
                        Kerala, Karnataka and Lakshadweep to protect the world’s
                        largest fish.
                      </span>
                    </v-flex>

                    <v-flex xs12 align-self-center text-center pa-2>
                      <v-btn
                        tile
                        large
                        dark
                        color="#e27725"
                        @click="
                          $vuetify.breakpoint.name == 'xs' ||
                          $vuetify.breakpoint.name == 'sm'
                            ? scrollMeXS()
                            : scrollMe()
                        "
                      >
                        <span
                          class="poppinsbold"
                          :style="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'font-size:14px'
                              : 'font-size:24px'
                          "
                        >
                          SAVE WHALE SHARKS NOW
                        </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout
              wrap
              justify-start
              pt-2
              pb-6
              pa-md-4
              pa-lg-0
              py-lg-4
              pb-lg-8
            >
              <v-flex xs12 align-self-center>
                <v-card outlined flat tile color="#527E33">
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm3 align-self-center text-center pa-2>
                      <v-img
                        contain
                        height="100px"
                        :src="require('./../../assets/images/wtilogo.png')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>

                    <v-flex
                      xs12
                      sm9
                      align-self-center
                      text-sm-left
                      text-xs-center
                      pa-2
                      style="color:#ffffff"
                    >
                      <span class="poppinsbold">
                        FOR A SECURE NATURAL HERITAGE OF INDIA
                      </span>
                      <br />
                      <br />
                      <span class="poppinssemibold">
                        CONTACT INFO
                        <br />
                        <span class="poppinsregular">
                          Address: F-13, Sector 8, National Capital Region
                          (NCR), Noida 201301, India Email: info@wti.org.in
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      slides: [
        require("./../../assets/images/Ele5.jpg"),
        require("./../../assets/images/c2.jpg"),
        require("./../../assets/images/c3.jpg"),
        require("./../../assets/images/c4.jpg"),
      ],

      wsslides: [
        require("./../../assets/images/ws1.jpg"),
        require("./../../assets/images/ws2.jpg"),
        require("./../../assets/images/ws3.jpg"),
        require("./../../assets/images/ws4.jpg"),
      ],

      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 2, nav: false },
        1264: { items: 2, nav: false },
        2560: { items: 2, nav: false },
      },
    };
  },
  methods: {
    scrollMe() {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollMeXS() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.texth {
  line-height: 1.5em;
  min-height: 9.5em;
  float: left;
  width: 100%;
}
.hovCls {
  color: #005f32;
  border-bottom: 3px solid #005f32;
}

.hovCls:hover {
  color: blue;
  border-bottom: 3px solid blue;
}
</style>
