<template>
  <div  >
      
    <MainBanner />
    <div  >
      <Banner 
      />
    </div> 
    <!-- <Quiz /> -->
  </div>
</template>
<script>
import MainBanner from "./mainBanner";
import Banner from "./Banner";  
export default {
  data() {
    return {
      isActive: false,
      appLoading: false,
      showSnackBar: false,
      timeOut: 5000,
      msg: null, 
    };
  },
  components: {
    MainBanner,
    Banner, 
  }, 
  beforeMount() {
    // this.getData();
  },
  methods: {
  
  },
};
</script>
